<script>
  import { fly } from "svelte/transition";

  export let show = false;
</script>

{#if show}
  <rightPanel class="shadow-lg" transition:fly={{ x: 650, opacity: 1, duration:600 }}>
    <div>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus sit
        minima ipsam tempore, illum, illo neque quam sunt voluptatum recusandae,
        temporibus unde! Omnis error ullam natus saepe eius quibusdam porro.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus sit
        minima ipsam tempore, illum, illo neque quam sunt voluptatum recusandae,
        temporibus unde! Omnis error ullam natus saepe eius quibusdam porro.
      </p>
      <p>
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Doloribus sit
        minima ipsam tempore, illum, illo neque quam sunt voluptatum recusandae,
        temporibus unde! Omnis error ullam natus saepe eius quibusdam porro.
      </p>
    </div>
  </rightPanel>
{/if}

<style>
  rightPanel {
    position: fixed;
    top: 0;
    right: 0;
    height: 100vh;
    padding: 2rem 1rem 0.6rem;
    border-left: 1px solid #aaa;
    background: #fff;
    overflow-y: auto;
    width: 50vw;
    min-width: 300px;
    max-width: 650px;
  }
</style>
