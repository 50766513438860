<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";

  // Stores
  import { authData } from "stores/auth_store.js";

  onMount(() => {
    console.log("logout component mounted...");
    $authData.token = "";
    push("/login");
  });

</script>

<h1>Logout</h1>
<br />
