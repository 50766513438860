<script>
  import ListCard from "./parts/list_card.svelte";
  // import Spinner from "common/spinner.svelte";
</script>

<div class="page-content container-fluid">
  <h3>Clients List</h3>
  <div class="row row-cols-1  row-cols-md-2 row-cols-lg-3 g-4">
    <ListCard>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam magni ea neque debitis incidunt fuga eos accusamus.</ListCard>
    <ListCard>Delectus aperiam dolore officia, doloribus voluptatum omnis? Exercitationem, veniam.</ListCard>
    <ListCard>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Totam magni ea neque debitis incidunt fuga eos accusamus.</ListCard>
    <ListCard>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo veniam, at harum placeat tempore rerum neque? Omnis aut animi perspiciatis, sint quaerat ullam fugit consectetur veritatis voluptas, asperiores, magni quibusdam?</ListCard>
    <ListCard>Delectus aperiam dolore officia, doloribus voluptatum omnis? Exercitationem, veniam.</ListCard>
    <ListCard>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quo veniam, at harum placeat tempore rerum neque? Omnis aut animi perspiciatis, sint quaerat ullam fugit consectetur veritatis voluptas, asperiores, magni quibusdam?</ListCard>
  </div>

</div>
