<script>
  export let inputId; // String - ID and FieldName
  export let inputLabel; // String
  export let inputForm; // Form object
  export let errChk; // Validations String Array
  export let errMsg; // String - Error Message
  export let autoComplete = "off";   // String
  export let inputInfo = "";  // String

  // Check validations array errChk
  let inputField = inputForm.getField(inputId);
  let isValid = errChk.every(inputValidation);
  function inputValidation(element) {
    let chk = inputId + "." + element;
    let mRet = $inputForm.hasError(chk);
    return mRet;
  }

  // Add Validation classes
  let validationClass;
  $: validationClass = () => {
    if ($inputField.value === "") {
      if ($inputField.errors.length === 0) {
        return "";
      } else {
        return $inputField.valid ? "is-valid" : "is-invalid";
      }
    } else {
      return $inputField.valid && $inputField.errors.length === 0
        ? "is-valid"
        : "is-invalid";
    }
  };
</script>
<div class="form-floating">
  <input
    id={inputId} 
    bind:value={$inputField.value}
    type="text"
    class="form-control {validationClass()} "
    placeholder="{inputLabel}"
    autocomplete="{autoComplete}"
  />
  <label for={inputId}>{inputLabel}</label>
  {#if !isValid}
    <div class="invalid-feedback">
      {errMsg}
    </div>
  {/if}
  {#if inputInfo!=""}
    <i class="input-info qtip tip-top" data-tip="{inputInfo}">i</i>
  {/if}
</div>