import { wrap } from "svelte-spa-router/wrap";

import Login from "pages/auth/login.svelte";
import SignUp from "pages/auth/signup.svelte";
import Logout from "pages/auth/logout.svelte";
import Forgot from "pages/auth/forgot.svelte";
import Verify from "pages/auth/verify.svelte";

import Dash from "pages/dash.svelte";
import NotFound from "pages/not_found.svelte";

import ClientList from "pages/client/client_list.svelte";
import ClientAdd from "pages/client/client_add.svelte";

import AdminList from "pages/admin/admin_list.svelte";

export default {
  "/login": wrap({
    component: Login,
    userData: { isPublic: true },
  }),
  "/signup": wrap({
    component: SignUp,
    userData: { isPublic: true },
  }),
  "/forgot": wrap({
    component: Forgot,
    userData: { isPublic: true },
  }),
  "/verify": wrap({
    component: Verify,
    userData: { isPublic: true },
  }),
  "/logout": wrap({
    component: Logout,
    userData: { isPublic: true },
  }),

  // Authenticated Routes (userData.isPublic == false or undefined)
  "/": wrap({
    component: Dash,
    // conditions: [(detail) => { return isLogged();}],
  }),
  "/client/list": wrap({
    component: ClientList,
    userData: { isPublic: false },
    // conditions: [(detail) => { return isLogged();}],
  }),
  "/client/add": wrap({
    component: ClientAdd,
    userData: { isPublic: false },
  }),
  "/admin/list": wrap({
    component: AdminList
  }),

  // The catch-all route must always be last
  "*": NotFound,
};
