<script>
  import { onMount } from "svelte";
  import { SvelteToast } from "@zerodevx/svelte-toast";
  import Router, { link, location, replace, push } from "svelte-spa-router";
  import active from "svelte-spa-router/active";
  import routes from "./routes";

  // Layouts
  import AuthLayout from "common/layouts/auth.svelte";
  import LeftNav from "common/layouts/leftnav.svelte";

  // Common Components
  import Spinner from "common/spinner.svelte";

  // Stores
  import { authData } from "stores/auth_store.js";

  // Init authData
  const initAuthData = {
    token:"",
    isRoutePublic:true,
    activeRoute:""
  }

  onMount(() => {
    // console.log("App mounted runs only once on First Page Load...");
    // $authData = initAuthData;
    $authData.token = "";
    $authData.isRoutePublic = true;
    $authData.activeRoute = "";
    console.log({ $authData });
  });

  // Toast options
  const toastOpts = {
    duration: 6000,
    pausable: true,
  };

  // Failed Route Condition
  function onRouteFailed(e) {
    replace("/login");
  }

  function onRouteLoading(event) {
    // Runs Every time on Route
    // Set AuthStore

    console.log({ event });
    console.log("location: " + event.detail.location);

    // $authData.isRoutePublic=true;
    if (event.detail.userData) {
      console.log("userdata: " + JSON.stringify(event.detail.userData));
      console.log("userdata.isPublic: " + event.detail.userData.isPublic);
      console.log("Setting authdata...");
      $authData.isRoutePublic = event.detail.userData.isPublic;
      if (event.detail.userData.activeRoute) $authData.activeRoute=event.detail.userData.activeRoute;
    } else {
      $authData.isRoutePublic=true;
      $authData.token = "";
    }

    console.log('xxxxx');
    console.log({ $authData });
    if ($authData.isRoutePublic == false) {
      if ($authData.token == "") {
        // Redirect to Login
        console.log("redirecting to...");
        // $authData.isRoutePublic;
        push("/login");
      }
    }
  }

  function routeLoaded(event) {
    // console.log("routeLoaded event");
    // // The first 5 properties are the same as for the routeLoading event
    // console.log("Route", event.detail.route);
    // console.log("Location", event.detail.location);
    // console.log("Querystring", event.detail.querystring);
    // console.log("Params", event.detail.params);
    // console.log("User data", event.detail.userData);
    // // The last two properties are unique to routeLoaded
    // console.log("Component", event.detail.component); // This is a Svelte component, so a function
    // console.log("Name", event.detail.name);
    // console.log({ isPublic });
    // console.log({ $authData });
  }

  //  $authData.isRoutePublic
</script>

{#if $authData.isRoutePublic}
  <AuthLayout>
    <div class="row">
      <div class="col-md-9 col-lg-8 mx-auto">
        <Router
          {routes}
          on:routeLoading={onRouteLoading}
          on:routeLoaded={routeLoaded}
        />
      </div>
    </div>
  </AuthLayout>
{:else}
<div class="container-fluid">
  <div class="row d-flex flex-nowrap">
    <div class="col-auto">
      <LeftNav />
    </div>
    <div class="col">
      <Router
        {routes}
        on:routeLoading={onRouteLoading}
        on:routeLoaded={routeLoaded}
      />
    </div>
  </div>
</div>
{/if}
<SvelteToast options={toastOpts} />

<!-- on:conditionsFailed={onRouteFailed} -->

<!-- 
<nav>
  <a use:link use:active href="/login">Login</a>
  <a use:link use:active href="/signup">Signup</a>

  <a use:link use:active href="/client/list">Clients List</a>
  <a use:link use:active href="/client/add">Add new Client</a>
  <a use:link use:active href="/logout">Logout</a>
</nav>
<p>The current page is: {$location}</p>
<p>The current page is:</p>
<pre>
  authData:
  {JSON.stringify($authData)};
  
  RouteData:
  {JSON.stringify(routeData)};
</pre> -->
