<script>
  import SidePanel from 'common/side-panel.svelte';
  let sidebar_show = false;

  const testAction = () => {
    console.log('111');
  };
</script>

<div class="page-nav container-fluid">
  <!-- Nav tabs -->
  <ul class="nav nav-pills" id="pills-tab" role="tablist">
    <li class="nav-item" role="presentation">
      <button
        on:click|preventDefault={testAction}
        class="nav-link active"
        id="pills-home-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-home"
        type="button"
        role="tab"
        aria-controls="pills-home"
        aria-selected="true">Home</button
      >
    </li>
    <li class="nav-item" role="presentation">
      <button
        on:click|preventDefault={testAction}
        class="nav-link"
        id="pills-profile-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-profile"
        type="button"
        role="tab"
        aria-controls="pills-profile"
        aria-selected="false">Profile</button
      >
    </li>
    <li class="nav-item" role="presentation">
      <button
        class="nav-link"
        id="pills-contact-tab"
        data-bs-toggle="pill"
        data-bs-target="#pills-contact"
        type="button"
        role="tab"
        aria-controls="pills-contact"
        aria-selected="false">Contact</button
      >
    </li>
  </ul>

  <hr />

  <!-- <div class="card shadow">
    <div class="card-body">
    </div>
  </div> -->
</div>
<div class="page-content container-fluid">
  <div class="row">
    <!-- Tab panes -->
    <div class="tab-content" id="pills-tabContent">
      <div
        class="tab-pane fade show active"
        id="pills-home"
        role="tabpanel"
        aria-labelledby="pills-home-tab"
      >
        <h3>Account Admin</h3>

        <button on:click={() => sidebar_show = !sidebar_show}>Toggle Sidebar</button>

        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
      </div>
      <div
        class="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <h3>Tab 2</h3>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
      </div>
      <div
        class="tab-pane fade"
        id="pills-contact"
        role="tabpanel"
        aria-labelledby="pills-contact-tab"
      >
        <h3>Third Tab</h3>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
        <p>
          Lorem ipsum, dolor sit amet consectetur adipisicing elit. Delectus
          itaque sequi voluptates reprehenderit nostrum at facilis dolorem magni
          eaque dignissimos temporibus veritatis dolores esse, odio alias
          distinctio corrupti laborum illo?
        </p>
      </div>
    </div>
  </div>
</div>



<SidePanel bind:show={sidebar_show} />