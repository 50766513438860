<script>
  import ListCard from "./parts/list_card.svelte";
</script>

<div class="page-content container-fluid">
  <h3>Add Client</h3>

  <div class="row row-cols-1  row-cols-md-2 row-cols-lg-3 g-4">
    <ListCard />
    <ListCard />
    <ListCard />
    <ListCard />
    <ListCard />
  </div>
</div>
