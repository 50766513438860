<script>
  // Constants/Layouts/Stores
  import k from "common/constants.js";

  // import { toast } from "@zerodevx/svelte-toast";
  import { link, push } from "svelte-spa-router";
  import active from "svelte-spa-router/active";

  import { form, field } from "svelte-forms";
  import { required, email } from "svelte-forms/validators";

  import InputText from "common/forms/input_text.svelte";
  import InputPwd from "common/forms/input_pwd.svelte";

  const first_name = field("first_name", "", [required()], k.ValidFieldOpts);
  const last_name = field("last_name", "", [], k.ValidFieldOpts);
  const account_email = field(
    "account_email",
    "",
    [required(), email()],
    k.ValidFieldOpts
  );
  const account_pwd = field("account_pwd", "", [required()], k.ValidFieldOpts);
  const confirm_pwd = field("confirm_pwd", "", [required()], k.ValidFieldOpts);
  const myForm = form(
    first_name,
    last_name,
    account_email,
    account_pwd,
    confirm_pwd
  );

  function setLogged() {
    // console.log($name1.value);
  }
</script>

<!-- <section>
  <input type="text" bind:value={$name.value} use:style={{ field: name, valid: "is-valid", invalid: "is-invalid" }}>
  {#if $myForm.hasError('name.required')}
    <div>Name is required</div>
  {/if}

  <button disabled={!$myForm.valid}>Send form</button>
</section>
 -->

<!-- <section>
  <input type="text" bind:value={$name.value} />
  <input type="password" bind:value={$password.value} />

  <button on:click={name.reset}>Reset name</button>
  <button on:click={password.reset}>Reset password</button>
  <button on:click={myForm.reset}>Reset form</button>
</section> -->

<h3 class="display-5 mb-4">Forgot Password!</h3>

<div class="row">
  <div class="col-md">
    <InputText
      inputId="first_name"
      inputLabel="First Name*"
      inputForm={myForm}
      errChk={["required"]}
      errMsg="First Name is Required"
    />
  </div>
  <div class="col-md">
    <InputText
      inputId="last_name"
      inputLabel="Last Name"
      inputForm={myForm}
      errChk={[]}
      errMsg=""
    />
  </div>
</div>

<div class="row">
  <div class="col-md">
    <InputText
      inputId="account_email"
      inputLabel="Email*"
      inputForm={myForm}
      errChk={["required", "email"]}
      errMsg="Valid Email Id is Required"
    />
  </div>
</div>

<div class="row">
  <div class="col-md">
    <InputPwd
      inputId="account_pwd"
      inputLabel="Password*"
      inputForm={myForm}
      errChk={["required"]}
      errMsg="Password is Required"
    />
  </div>
  <div class="col-md">
    <InputText
      inputId="confirm_pwd"
      inputLabel="Confirm Password"
      inputForm={myForm}
      errChk={["required"]}
      errMsg="Password should match"
    />
  </div>
</div>

<div class="d-grid">
  <button
    type="button"
    class="btn btn-primary my-2 shadow"
    on:click|preventDefault={setLogged}>Sign Up</button
  >
  <p>
    I already have an Account!
    <a use:link use:active href="/login">Login!</a>
  </p>
</div>
<!-- </form> -->
