<script>
  import active from "svelte-spa-router/active";
  import { link } from "svelte-spa-router";

  // import API from "services/api.js";

  // import AuthLayout from "common/layouts/auth.svelte";

  // async function fetchPokemon() {
  //   const response = await fetch(
  //     `https://pokeapi.co/api/v2/pokemon?limit=${limit}`
  //   );
  //   const res = response.json();
  //   return await res;
  // }

  // const doLogin = async (req) => {
  //   try {
  //     const response = await API.login(req);
  //     console.log({ response });
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
</script>

<h3 class="display-5 mb-4">Verification Code!</h3>
<p>
  <a class="ms-2" use:link use:active href="/signup">Sign Up!</a>
</p>
