import { writable } from 'svelte/store';

export const authData = writable({
  token:'',
  isRoutePublic:true,
  activeRoute:''
});

// id:0,
// name:'',

// const BookStore = writable([{
//     id: 1,
//     title: "The Way of Kings",
//     author: "Brandon Sanderson"
// }]);

// export default BookStore;

// STORE EXAMPLE
// import { writable } from 'svelte/store';

// const blankUser = {
//   id:0,
//   name:'',
//   token:''
// };
// const authData = writable(blankUser);
// const authStore = {
//   subscribe: authData.subscribe,
//   set: authData.set,
//   logout: authData.set(blankUser)
// }
// export default authStore;
