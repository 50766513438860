import axios from "axios";
import { toastRed } from "common/toasts.js";

console.log(import.meta.env);
let baseUrl = "http://localhost:3015";
// const isProd = import.meta.env.MODE === "production";
const isProd = false;

// Create an instance of axios to use the same base url.
if (isProd) baseUrl = "https://wedbapi.insagy.com";
const axiosAPI = axios.create({
  baseURL: baseUrl,
});

// console.log({ baseUrl });

// Special method for unAuthenticated requests like login/signup/verify/forgot pwd
const openReqest = (requestData, openUrl, cbErr, cbOk) => {
  const headers = {
    accept: "application/json",
    "Content-Type": "application/json",
  };
  const data = JSON.stringify(requestData);
  // const loginUrl = "/users/login";
  //using the axios instance to perform the request that received from each http method
  return axiosAPI({
    method: "post",
    url: openUrl,
    headers,
    data,
  })
    .then((res) => {
      if (typeof cbOk === "function") {
        cbOk();
      }
      return Promise.resolve(res.data);
    })
    .catch((error) => {
      if (error.response) {
        // Request made and server responded
        if (error.response.data.error.message) {
          toastRed(error.response.data.error.message);
        }
      } else if (error.request) {
        // The request was made but no response was received
        toastRed("No Server Response.. Please try later..");
      } else {
        // Something happened in setting up the request that triggered an Error
        toastRed("Unknown Error - " + error.message);
      }
      if (typeof cbErr === "function") {
        cbErr();
      }
      return Promise.reject(error);
    });
  // NOT WORKING (Should handle the finally and run after completion for Callback)
  // .then(() => {
  //   console.log("-----------------3");
  //  if (typeof cb === 'function') {
  //    cb();
  //  }
  // });
};

/* 
  ///// Handled thru openRequest------
  const signupReqest = (request) => {
  const headers = { 
    'accept': 'application/json',
    'Content-Type': 'application/json'
  }
  const data = JSON.stringify(request);
  const loginUrl = "/users/login";
  //using the axios instance to perform the request that received from each http method
  return axiosAPI({
    method : "post",
    url: loginUrl,
    headers,
    data,
  })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};
 */
// implement a method to execute all the request from here.
const apiRequest = (method, url, params, request) => {
  // TO BE DONE :::::: params to be converted to querystring from object

  const headers = {
    Authorization: "",
  };

  let qryString = "";
  if (params && params != "") {
    qryString = "?" + params;
  }
  const fullURL = url + qryString;

  //using the axios instance to perform the request that received from each http method
  return axiosAPI({
    method,
    url: fullURL,
    data: request,
    headers,
  })
    .then((res) => {
      return Promise.resolve(res.data);
    })
    .catch((err) => {
      return Promise.reject(err);
    });
};

// function to execute Login request
const login = (request, cbERR = () => {}, cbOK = () => {}) =>
  openReqest(request, "/users/login", cbERR, cbOK);

// function to execute Login request
const signup = (request, cbERR = () => {}, cbOK = () => {}) =>
  openReqest(request, "/users/signup", cbERR, cbOK);

// function to execute the http get request
const get = (url, request) => apiRequest("get", url, request);

// function to execute the http delete request
const deleteRequest = (url, request) => apiRequest("delete", url, request);

// function to execute the http post request
const post = (url, request) => apiRequest("post", url, request);

// function to execute the http put request
const put = (url, request) => apiRequest("put", url, request);

// function to execute the http path request
const patch = (url, request) => apiRequest("patch", url, request);

// expose your method to other services or actions
const API = {
  login,
  signup,
  get,
  delete: deleteRequest,
  post,
  put,
  patch,
};
export default API;
