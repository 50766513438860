<script>
  // Constants/Layouts/Stores
  import KS from "common/constants.js";
  import API from "services/api.js";
  import { toastRed, toastGreen } from "common/toasts.js";

  import Spinner from "common/spinner.svelte";
  import { authData } from "stores/auth_store.js";

  import active from "svelte-spa-router/active";
  import { link, push } from "svelte-spa-router";

  // Form Inputs and Validators
  import InputText from "common/forms/input_text.svelte";
  import InputPwd from "common/forms/input_pwd.svelte";
  import { form, field } from "svelte-forms";
  import {
    required,
    // email,
    // pattern,
  } from "svelte-forms/validators";

  // Create Form and Fields
  const user_email = field("user_email", "", [required()], KS.ValidFieldOpts);
  // [required(), email()],
  const user_pwd = field("user_pwd", "", [required()], KS.ValidFieldOpts);
  // [required(), pattern(KS.pwdRegx)],
  const myForm = form(user_email, user_pwd);
  let apiLoading = false;

  const submitAction = async () => {
    if (apiLoading) return;
    await myForm.validate();
    if ($myForm.valid) {
      apiLoading = true;
      const reqData = {
        email: $user_email.value,
        password: $user_pwd.value,
      };
      // console.log({reqData});
      const res = await API.login(reqData, onSubmitComplete);
      console.log({ res });
      if (res && res.token) {
        $authData.token = res.token;
        push("#/client/add");
        apiLoading = false;
        toastGreen("Welcome InsAgy!");
      }
    } else {
      toastRed("Input Validation Error!");
    }
  };

  const onSubmitComplete = () => {
    apiLoading = false;
  };


  // const doLogin = async (req) => {
  //   try {
  //     const response = await API.login(req);
  //     console.log({ response });
  //     return response;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };
</script>

<h3 class="display-5 mb-4">Welcome back!</h3>

<!-- Sign In Form -->
<!-- svelte-ignore component-name-lowercase -->
<form>
  <InputText
    inputId="user_email"
    inputLabel="Email*"
    inputForm={myForm}
    errChk={["required", "email"]}
    errMsg="Valid Email Id is Required"
    autoComplete="username"
  />

  <InputPwd
    inputId="user_pwd"
    inputLabel="Password*"
    inputForm={myForm}
    errChk={["required", "pattern"]}
    errMsg="Strong password is Required"
    autoComplete="current-password"
    inputInfo="Minimum length of 8 characters with at least an alphabet, a digit and a special character"
  />

  <!-- <div class="form-check mb-3">
    <input
      class="form-check-input"
      type="checkbox"
      value=""
      id="rememberPasswordCheck"
    />
    <label class="form-check-label" for="rememberPasswordCheck">
      Remember password
    </label>
  </div> -->

  <div class="d-grid">
    <button
      type="button"
      class="btn btn-primary my-2 shadow"
      on:click|preventDefault={submitAction}
      >Login
    </button>
    <p>
      <a use:link use:active href="/forgot">Forgot Password!</a>
    </p>
    <hr />
    <p>
      I don't have an Account!
      <a class="ms-2" use:link use:active href="/signup">Sign Up!</a>
    </p>
  </div>
</form>
