//// App Constants
const ValidFieldOpts =  {
  valid: true,
  checkOnInit: false
}

// const pwdRegx = new RegExp(`^(?=.*[A-Za-z])(?=.*\\d)(?=.*[@$!%*#?&])[A-Za-z\\d@$!%*#?&]{8,99}$`, 'gm');
const pwdRegx = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

export default {
  ValidFieldOpts,
  pwdRegx
}