<script>
  // Constants/Layouts/Stores
  import KS from "common/constants.js";
  import API from "services/api.js";
  import AuthLayout from "common/layouts/auth.svelte";
  import { toastRed, toastGreen } from "common/toasts.js";
  import Spinner from "common/spinner.svelte";

  import { link, push } from "svelte-spa-router";
  import active from "svelte-spa-router/active";

  import { form, field } from "svelte-forms";
  import {
    required,
    // email,
    matchField,
    // pattern,
  } from "svelte-forms/validators";

  import InputText from "common/forms/input_text.svelte";
  import InputPwd from "common/forms/input_pwd.svelte";

  // Form Inputs
  const first_name = field("first_name", "", [required()], KS.ValidFieldOpts);
  const last_name = field("last_name", "", [], KS.ValidFieldOpts);
  const account_email = field(
    "account_email",
    "",
    [required()],
    KS.ValidFieldOpts
  );
  const account_pwd = field("account_pwd", "", [required()], KS.ValidFieldOpts);
  // [required(), pattern(KS.pwdRegx)],
  const confirm_pwd = field(
    "confirm_pwd",
    "",
    [required(), matchField(account_pwd)],
    KS.ValidFieldOpts
  );
  const myForm = form(
    first_name,
    last_name,
    account_email,
    account_pwd,
    confirm_pwd
  );
  let apiLoading = false;

  const submitAction = async () => {
    if (apiLoading) return;
    await myForm.validate();
    if ($myForm.valid) {
      apiLoading = true;
      const reqData = {
        firstName: $first_name.value,
        lastName: $last_name.value,
        email: $account_email.value,
        password: $account_pwd.value,
      };
      // console.log({reqData});
      const res = await API.signup(reqData, onSubmitComplete);
      console.log({ res });
      if (res && res == "done") {
        console.log("-------------response rcvd");
        apiLoading = false;
        toastGreen("Welcome to InsAgy! Please login..");
        push("/login");
      }
    } else {
      toastRed("Input Validation Error!");
    }
  };

  const onSubmitComplete = () => {
    apiLoading = false;
  };

  // const doSignup = async (req) => {
  //   try {
  //     const response = await API.signup(req);
  //     console.log({ response });
  //     return response;
  //   } catch (error) {
  //     console.error({error});
  //   }
  // };
</script>

<!-- <section>
  <input type="text" bind:value={$name.value} use:style={{ field: name, valid: "is-valid", invalid: "is-invalid" }}>
  {#if $myForm.hasError('name.required')}
    <div>Name is required</div>
  {/if}

  <button disabled={!$myForm.valid}>Send form</button>
</section>
 -->

<!-- <section>
  <input type="text" bind:value={$name.value} />
  <input type="password" bind:value={$password.value} />

  <button on:click={name.reset}>Reset name</button>
  <button on:click={password.reset}>Reset password</button>
  <button on:click={myForm.reset}>Reset form</button>
</section> -->

<!-- svelte-ignore component-name-lowercase -->
<form>
  <h3 class="display-5 mb-4">Let&lsquo;s Signup!</h3>

  <div class="row">
    <div class="col-md">
      <InputText
        inputId="first_name"
        inputLabel="First Name*"
        inputForm={myForm}
        errChk={["required"]}
        errMsg="First Name is Required"
      />
    </div>
    <div class="col-md">
      <InputText
        inputId="last_name"
        inputLabel="Last Name"
        inputForm={myForm}
        errChk={[]}
        errMsg=""
      />
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <InputText
        inputId="account_email"
        inputLabel="Email*"
        inputForm={myForm}
        errChk={["required", "email"]}
        errMsg="Valid Email Id is Required"
        autoComplete="username"
      />
    </div>
  </div>

  <div class="row">
    <div class="col-md">
      <InputPwd
        inputId="account_pwd"
        inputLabel="Password*"
        inputForm={myForm}
        errChk={["required", "pattern"]}
        errMsg="Strong password is Required"
        autoComplete="new-password"
        inputInfo="Minimum length of 8 characters with at least an alphabet, a digit and a special character"
      />
    </div>
    <div class="col-md">
      <InputPwd
        inputId="confirm_pwd"
        inputLabel="Confirm Password*"
        inputForm={myForm}
        errChk={["required", "match_field"]}
        errMsg="Password should match"
        autoComplete="new-password"
      />
    </div>
  </div>

  <div class="d-grid">
    <button
      type="button"
      class="btn btn-primary my-2 shadow"
      on:click|preventDefault={submitAction}
    >
      {#if apiLoading}
        <Spinner />
      {:else}
        Sign Up
      {/if}
    </button>
    <p>
      I already have an Account!
      <a class="ms-2" use:link use:active href="/login">Login!</a>
    </p>
    <hr />
    <p>
      <strong>No</strong>, I am not an Insurance Agent!
      <a class="ms-2" href="https://insagy.com/tag/policy-holders/"
        >Know more!</a
      >
    </p>
  </div>
  <!-- </form> -->
</form>
