<div class="col">
  <div class="card shadow">
    <div class="card-body">
      <blockquote class="blockquote blockquote-custom bg-white px-3 pt-4">
        <div class="blockquote-custom-icon bg-info shadow-1-strong">
          <i class="fa fa-quote-left text-white"></i>
        </div>
        <p class="mb-0 mt-2 font-italic">
          <slot />
        </p>
        <footer class="blockquote-footer pt-4 mt-4 border-top">
          Someone famous in
          <cite title="Source Title">Source Title</cite>
        </footer>
      </blockquote>
    </div>
    <div class="card-footer">
      Card footer
    </div>
  </div>
</div>


<style>
.card {
  border-top-left-radius: 25px !important;
}
.blockquote-custom {
  position: relative;
  font-size: 1.1rem;
}
.blockquote-custom-icon {
  width: 50px;
  height: 50px;
  border-radius: 50% !important;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -16px;
  left: -16px;
}
</style>