import { toast } from '@zerodevx/svelte-toast'

export const toastGreen = m => toast.push(m, {
  theme: {
    '--toastBackground': '#48BB78',
    '--toastBarBackground': '#2F855A'
  }
});

export const toastRed = m => toast.push(m, {
  theme: {
    '--toastBackground': '#F56565',
    '--toastBarBackground': '#C53030'
  }
});

export const toasts = m => toast.push(m,{
  theme: {
    '--toastBackground': '#338EC9',
    '--toastBarBackground': '#025B92'
  }
});
