<div class="container-fluid ps-md-0">
  <div class="row g-0">
    <div class="d-none d-md-flex col-md-4 col-lg-6 auth-bg-image" />
    <div class="col-md-8 col-lg-6">
      <div class="login d-flex align-items-center py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-9 col-lg-8 mx-auto mb-5">
              <img
                class="auth-logo d-block shadow mb-1"
                src="/img/insagy_logo.svg"
                alt="Logo"
                width="200"
                height="auto"
              />
              <p class="auth-logo-text">Best software for Insurance Agents!</p>
            </div>
          </div>
          <slot />
        </div>
      </div>
    </div>
  </div>
</div>

<style>
  /* Auth Layout */
  /* .auth-logo {
  margin-left: -70px;
} */
  .auth-bg-image {
    background-image: url("/img/login_banner.jpg");
    background-size: cover;
    background-position: center;
  }
  .login {
    min-height: 100vh;
  }
  .auth-logo-text {
    font-size: 12px;
    font-style: italic!important;
  }
</style>
